.register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5rem 0;

  &__container {
    display: flex;
    flex-direction: column;
    background-color: #ececec;
    font-family: "Roboto", sans-serif;

    header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2rem 0;
      background: linear-gradient(100.75deg, #ea1e23 0%, #f47014 100%);
      color: white;

      h1 {
        font-weight: 300;
        font-size: 3rem;
        margin: 5rem 0 2rem 0;
      }
    }
  }

  &__title {
    font-weight: 300;
    font-size: 3.2rem;
    text-transform: uppercase;
  }

  &__form {
    &__label {
      border: 2px solid black;
    }
    &--error {
      box-shadow: 0px 0px 1px 2px rgba(226, 15, 15, 0.664) !important;
    }
  }

  &__form__divider {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }

  &__form__divider__column {
    display: flex;
    flex-direction: column;
    align-content: space-around;
    width: 100%;
  }

  &__error {
    border-radius: 2px;
    z-index: 3000;
    background: red;
    width: 100%;
    height: 2px;
    top: 20px;
    left: 90%;
  }

  form {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    width: 100%;
    max-width: 680px;

    input {
      border: none;
      padding: 1rem;
      border-radius: 2px;
      margin: 0.5rem 0.3rem;
      box-shadow: 0px 1px 4px 0 rgba(150, 150, 150, 0.3);
      font-size: 1.1rem;
    }

    select {
      border: none;
      padding: 1rem;
      border-radius: 2px;
      margin: 0.5rem 0.3rem;
      box-shadow: 0px 1px 4px 0 rgba(150, 150, 150, 0.3);
      font-size: 1rem;
      width: 100%;
      color: grey;
      font-weight: 600;
    }
  }
}
.btn {
  border-radius: 2px;
  margin: 4px;
  padding: 1rem;
  border: none;
  background-color: #141414;
  box-shadow: 0px 1px 4px 0 rgba(150, 150, 150, 0.3);
  color: white;
  font-weight: 400;
  font-size: 1.1rem;

  &:hover {
    background-color: #1f1f1f;
  }
}

.modal-sending {
  z-index: 3000;
  position: fixed;
  display: flex;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 450ms;

  &--open {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    width: 32px;
    height: 5px;
    border-radius: 4px;
    background: transparent;
    border: 2px dashed #222222;
    animation: spinnericon 450ms alternate infinite;
  }

  &__content {
    background-color: #ececec;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 1rem 2rem;
    box-shadow: 0px 1px 5px 0px rgba(50, 50, 50, 0.5);
    h3 {
      font-weight: 300;
      font-size: 1.2rem;
    }
  }
}

.when-sent {
  z-index: 3000;
  width: 0px;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  opacity: 0;
  background: rgba(50, 50, 50, 0.5);
  pointer-events: none;
  transition: 650ms;

  &--open {
    pointer-events: visible;
    width: 100%;
    opacity: 1;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    bottom: 0;
    padding: 2rem 4rem;
    background: linear-gradient(102.29deg, #ea1e23 0%, #f47014 100%);
    font-weight: 300;
    color: white;

    h1 {
      font-size: 5rem;
      font-weight: 300;
    }

    h3 {
      font-weight: 300;
      margin: 20px 0;
      line-height: 2rem;
    }
  }

  &__button {
    position: absolute;
    top: 0;
    right: 10%;
    a {
      color: white;
      font-size: 1.2rem;
    }
  }

  &__download {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    a {
      margin: 20px;
    }
  }
}

@keyframes spinnericon {
  from {
    opacity: 0.5;
    transform: translateX(-35px);
  }
  to {
    opacity: 1;
    transform: translateX(35px);
  }
}

.features {
  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
  background-color: white;

  h1 {
    font-size: 2.3rem;
    text-align: center;
    margin: 2rem 0 6rem 0;
  }

  ul {
    list-style: none;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 2rem 0;
    span {
      font-size: 1.2rem;
      font-weight: 400;
      font-weight: 300;
      margin: 0 10px;
    }
  }

  &__video {
    max-width: 400px;
    max-height: 260px;
    overflow: hidden;
    border-radius: 4px;
    margin: 5px 0;
  }

  &__bold {
    font-weight: bold !important;
  }

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0.5rem;
    color: white;
    background: linear-gradient(100.75deg, #ea1e23 -80%, #f47014 100%);
    //border: 3px solid #ccc;
    border-radius: 100%;
  }

  &__column {
    display: flex;
    flex-direction: column;
    margin: 0 2rem;
  }

  &__row {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }

    img {
      width: 100%;
      max-width: 400px;
      max-height: 400px;
      border-radius: 8px;
    }
  }

  &__desktop {
    padding: 4rem 0 0 0;
    margin: 4rem 0 0 0;

    &__img {
      margin: 60px 0;
      background-color: white;
      padding: 8px;
      max-width: 800px;
      box-shadow: 0px 1px 17px rgba(0, 0, 0, 0.12);
    }

    img {
      width: 100%;
      max-width: 800px;
    }
  }
}

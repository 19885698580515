.download {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5rem;
  color: white;
  background: linear-gradient(281.77deg, #f47014 0%, #ea1e23 100%);

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 300;
    letter-spacing: 1px;
    margin: 0 0 2rem 0;
  }

  img {
    width: 72px;
    margin: 0 20px;
  }
}

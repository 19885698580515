@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap");

html,
* {
  padding: 0;
  margin: 0;
}

body {
  background-color: #f6f6f6;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.header {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  padding: 0 0 4rem 0;
  align-items: center;
  color: #f6f6f6;
  background: linear-gradient(100.75deg, #ea1e23 0%, #f47014 100%);

  h3 {
    font-weight: 300;
    font-size: 1.8rem;
    padding: 20px 0;
  }

  &__description {
    margin-top: 7rem;
    word-spacing: 2px;
    width: 100%;
    max-width: 380px;
    text-align: center;
  }

  &__logo {
    img {
      width: 100%;
      max-width: 310px;
      min-width: 120px;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
  }

  &__partners {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }

    img {
      width: 100%;
      max-width: 220px;
      margin: 0 2px;
    }
  }
}

.menu {
  z-index: 400;
  display: none;
  position: fixed;
  background: linear-gradient(100.75deg, #ea1e23 0%, #f47014 100%);
  width: 100%;

  @media screen and (min-width: 768px) {
    display: block;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
  }

  li {
    font-size: 1.1rem;
    font-weight: 300;
    margin: 0 20px;
    padding: 20px 10px;
    text-align: center;
  }

  a {
    text-decoration: none;
    color: white;
    &:hover {
      text-decoration: underline;
    }
  }

  img {
    width: 100%;
    max-width: 150px;
    min-width: 140px;
  }

  &__login {
    text-align: right;
    a {
      background-color: #222222;
      padding: 0.5rem 1rem;
      border-radius: 3px;
      font-weight: bold;
      transition: 200ms;

      &:hover {
        font-weight: 400;
        padding: 0.6rem 1rem;
        text-decoration: none;
      }
    }
  }
}

.menu-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  //background: linear-gradient(100.75deg, #EA1E23 0%, #F47014 100%);

  @media screen and (min-width: 768px) {
    display: none;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #222222;
    transition: 400ms;
    width: 100%;
    transform: translate(-280px, -90px) scale(0.2, 0.5);
    height: 0px;
    opacity: 0;
    border-radius: 100%;
    @media screen and (min-width: 768px) {
      display: none;
    }
    &--open {
      opacity: 1;
      transform: translateX(0) scale(1, 1);
      height: 100%;
      border-radius: 0;
    }
  }

  &__button {
    z-index: 300;
    position: fixed;
    display: block;
    transition: 200ms;
    border: none;
    padding: 1rem 1.2rem;
    color: white;
    font-size: 1.4rem;
    font-weight: 200;
    background: #222222;
    border-radius: 2px;
    top: 0;
    left: 0;
    border-radius: 100%;
    margin: 2rem;
    outline: none;
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
  }

  li {
    font-size: 1.1rem;
    margin: 0 20px;
    padding: 20px 10px;
  }

  a {
    text-decoration: none;
    color: white;
    &:hover {
      text-decoration: underline;
    }
  }

  img {
    width: 100%;
    max-width: 200px;
  }

  &__login {
    text-align: center;
    @media screen and (min-width: 768px) {
      text-align: right;
    }
    a {
      background-color: #222222;
      padding: 0.5rem 1rem;
      border-radius: 3px;
      font-weight: bold;
      transition: 200ms;

      &:hover {
        font-weight: 400;
        padding: 0.6rem 1rem;
        text-decoration: none;
      }
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;
  color: #f6f6f6;
  background-color: #202020;
  padding: 6rem 0 0 0;

  a {
    text-decoration: none;
    color: white;
    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
  }

  li {
    margin: 0.6rem 2rem;
  }

  &__links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }

  &__description {
    padding: 2rem;
    line-height: 25px;
    width: 100%;
    max-width: 370px;

    img {
      width: 200px;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
  }

  &__partners {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 640px) {
      flex-direction: row;
    }

    img {
      width: 100%;
      max-width: 200px;
      margin: 0 10px;
    }
  }

  &__social {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 20px 0;
    width: 100%;
    img {
      width: 32px;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    padding: 10px 0;
  }
}

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5rem 0;

  h1 {
    font-weight: 300;
    font-size: 8rem;
    margin: 8rem 0 0 0;
    background: linear-gradient(-67deg, #ea1e23 0%, #f47014 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h2 {
    font-weight: 300;
  }

  a {
    background: linear-gradient(-67deg, #ea1e23 0%, #f47014 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border-bottom: 2px solid #ea1e23;
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
}

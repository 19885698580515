.situation{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: linear-gradient(102.29deg, #EA1E23 0%, #F47014 100%);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);

  padding: 4rem 0;

  h1{
    color: white;
    font-weight: 400;
    font-size: 1.8rem;
    margin: 20px 0;
  }

  ul{
    display: flex;
    flex-direction: column;
    list-style: none;

    @media screen and (min-width: 768px){
      flex-direction: row;
    }
  }

  li{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 10px 2rem;
    padding: 2rem 4rem;

    border-radius: 4px;
    background-color: white;

    font-size: 1.2rem;

    span:nth-child(1){
      font-weight: 500;
    }
    span:nth-child(2){
      font-weight: 400;
    }
  }

  &__prevention{
    cursor: pointer;
    font-weight: 400;
    color: white;
    width: 220px;
    padding: 1rem;
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #202020;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    transition: 400ms;

    &:hover{
      transform: scale(1.05, 1.05);
    }

    &__content{
      text-align: center;
      animation: fade 400ms linear;
      transition: 400ms ease-in-out;
      transform-origin: top;
      transform: scale(1, 0);
      height: 0;
      opacity: 0;
  
      &--show{
        height: 400px;
        transform: scale(1, 1);
        opacity: 1;
      }
      
      h1{
        font-size: 2.8rem;
        margin: 0;
      }

      span{
        color: white;
      }

      ul{
        display: flex;
        flex-direction: column;
        pointer-events: none;
      }

      li{
        padding: 1rem;
        margin: 2px;
        transition: 200ms;

        &:hover{
          opacity: .8;
          transform: scale(1.05, 1.05);
        }
      }
    }
  }

  &__green{
    margin: 10px 0;
    font-size: 1.8rem;
    color: rgb(146, 219, 35);
  }

  &__orange{
    margin: 10px 0;
    font-size: 1.8rem;
    color: orangered;
  }

  &__red{
    margin: 10px 0;
    font-size: 1.8rem;
    color: red;
  }
  
}

// @keyframes fade{
//   from{
//     height: 0px;
//     opacity: 0;
//   }
//   to{
//     height: 400px;
//     opacity: 1;
//   }
// }

.introduce {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0 8rem 0;
  background-color: white;
  font-size: 1.2rem;

  h1 {
    margin: 0.5rem 0;
    padding: 2rem 0;
    font-weight: 500;
    font-size: 3rem;
  }

  img {
    width: 230px;
    padding: 2rem;
  }

  &__container {
    width: 100%;
    max-width: 760px;
    line-height: 30px;
  }

  &__video {
    max-width: 719px;
    max-height: 405px;
    overflow: hidden;
    border-radius: 4px;
    margin: 4rem 0;
  }
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}
